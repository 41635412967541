.scrollBarStyle::-webkit-scrollbar {
  height: 1px;
  display: none;
}

/* Track */
.scrollBarStyle::-webkit-scrollbar-track {
  background: #888;
  border-radius: 5px;
  width: 1px;
  height: 1px;
}
.scrollBarStyle {
  scrollbar-width: thin;
}

/* Handle */
.scrollBarStyle::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 2px;
  width: 1px;
}

/* Handle on hover */
.scrollBarStyle::-webkit-scrollbar-thumb:hover {
  background: #555;
}
